import { AiOutlineMail } from '@react-icons/all-files/ai/AiOutlineMail';
import { AiOutlineMobile } from '@react-icons/all-files/ai/AiOutlineMobile';

import { Col, Row } from 'antd';
import { H1Bold, H3, H3Bold, TextSmall } from '../shared/typography';
import React, { useState } from 'react';

import { BtnSecoundary } from '../shared/button';
import { RiUserLocationLine } from '@react-icons/all-files/ri/RiUserLocationLine';

import styled from '@emotion/styled';
import { theme } from '../shared/theme';

const StyledForm = styled.form`
  label {
    font-weight: 600;
    font-size: 1rem;
  }
  input,
  textarea {
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    padding: 10px 12px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    background-color: transparent;
    color: inherit;
    text-align: inherit;
    height: auto;
    border-style: none;
    border: 1px solid grey;

    &:selection {
      border-style: none;
      border: 1px solid grey;
    }
  }
`;
const ContactComponent: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [mobile, setMobile] = useState('');

  function encode(data: any) {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&');
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const form = e.target;
    fetch('https://getform.io/f/d8fe3394-077a-4822-9813-02d3a002e0ea', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        navn: name,
        email: email,
        telefon: mobile,
        besked: msg
      }),
    })
      .then(() => {
        setTimeout(() => {
          setSuccess(true);
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          setIsError(true);
        }, 1000);
      });
  };

  return (
    <Row justify="center" style={{ marginTop: '4rem' }}>
      <Col xs={24} lg={12} style={{ padding: '3rem 2rem 2rem 2rem' }}>
        <H1Bold style={{ textAlign: 'center' }}>Kontakt</H1Bold>
        <TextSmall>Har du spørgsmål så kontakt os!</TextSmall>
        {success && <H3 style={{color: 'black'}}>{'Tak for din besked! Vi vender tilbage hurtigst muligt!'}</H3>}
          {isError && (
            <H3Bold style={{color: 'red'}}>
              {'Der skete en fejl - prøv igen!'}
            </H3Bold>
          )}
        <StyledForm
          name="Kontakt"
          method="POST"
          onSubmit={handleSubmit}
        >
          <div hidden>
            <label>
              Do not fill this field:{' '}
              <input
                name="bot-field"
                onChange={(e) => setName(e.target.value)}
              />
            </label>
          </div>
          <label htmlFor="name">Navn*</label>
          <input
            id="name"
            name="name"
            required
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
            disabled={success}
          />
          <label htmlFor="email">E-mail*</label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            disabled={success}
          />
          <label htmlFor="phone">Telefon*</label>
          <input
            id="phone"
            name="phone"
            type="tel"
            required
            onChange={(e) => setMobile(e.target.value)}
            value={mobile}
            disabled={success}
          />
          <label htmlFor="msg">Kommentar</label>
          <textarea
            id="msg"
            name="msg"
            onChange={(e) => setMsg(e.target.value)}
            value={msg}
            disabled={success}
          />
          <TextSmall>
            <BtnSecoundary disabled={success} type="submit">Send</BtnSecoundary>
          </TextSmall>
        </StyledForm>
      </Col>
      <Col
        xs={24}
        lg={12}
        style={{
          color: '#fff',
          background: theme.colors.brandLight.darkred,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '2rem 0',
        }}
      >
        <AiOutlineMobile
          size={80}
          style={{ alignSelf: 'center', margin: '1rem' }}
        />
        <Row justify="center">
          <p>+45 29727622</p>
        </Row>

        <AiOutlineMail
          size={80}
          style={{ alignSelf: 'center', margin: '1rem' }}
        />
        <Row justify="center">
          <p>jesper@mansted-wine.dk</p>
        </Row>
        <RiUserLocationLine
          size={80}
          style={{ alignSelf: 'center', margin: '1rem' }}
        />
        <Row justify="center">
          <p>
            Vestre Kvartergade 6 <br />
            2650 Hvidovre
          </p>
        </Row>
      </Col>
      <Col span={20}>
        <img
          alt="Mansted wine logo"
          src="/logo/mansted-wine-logo.jpg"
          style={{
            width: '100%',
            height: 'auto',
            marginTop: '2rem',
            borderRadius: '5px',
          }}
        />
      </Col>
    </Row>
  );
};
export default ContactComponent;
