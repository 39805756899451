import ContactComponent from '../components/contact/contact-component';
import React from 'react';
import Seo from '../components/Seo';
import { StyledContent } from '../components/layout/layout';

interface Props {
  data: {
    allContentfulAboutPage: {
    };
  };
}

const Contact: React.FC<Props> = ({ data }: Props) => {

  return (
    <StyledContent>
      <Seo
        title={'Kontakt'}
        image={'https://www.mansted-wine.dk/logo/mansted-wine-logo.jpg'}
        description={"Her finder du informationer og kontaktformular - mangler du svar på spørgsmål, eller en godt råd til en vin? Så kontakt os endelig"}
      />
      <ContactComponent />
    </StyledContent>

  );
};


export default Contact